import React, { useState, useRef, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
//import UIkit from "uikit"
import 'uikit/dist/css/uikit.css'
import Layout from "../layouts"

import "../styles/reviews.scss";

import ReactStars from "react-rating-stars-component";

import Header from "../components/header/header"

import ReviewCard from "../components/review-card/review-card"



// markup
const Reviews = () => {
  const data = useStaticQuery(graphql`
      {
            strapiReviewPage {
              PageComponents
              Seo {
                metaDescription
                metaTitle
              }
            }

            allStrapiReviews {
              nodes {
                Title
                Description
                Author
                Image {
                  localFile {
                    childImageSharp{
                      gatsbyImageData(
                        placeholder: BLURRED
                        formats: [AUTO, WEBP]
                      ) 
                    }
                  }
                }
                Alt
              }
            }

            allFile {
              edges {
                node {
                  publicURL
                  id
                  name
                  childImageSharp {
                    fluid(quality: 90, maxWidth: 1900) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                    gatsbyImageData(
                      placeholder: BLURRED
                      formats: [AUTO, WEBP]
                    )
                  }
                }
              }
            }
      }
    `)
  function getImgUrl(localFile___NODE) {

    var imgUrl = { imgName: null, basicUrl: null, fluid: null, gatsbyImageData: null }

    data.allFile.edges.map((item, index) => {

      if (item.node.id === localFile___NODE) {
        // if(item.node.publicURL.endsWith(".svg")){
        //   imgUrl.basicUrl = item.node.publicURL
        // } else {
        //   imgUrl.fluid = item.node.childImageSharp.fluid
        //   imgUrl.basicUrl = item.node.publicURL
        // }
        imgUrl.imgName = item.node.name ? item.node.name : null
        imgUrl.basicUrl = item.node.publicURL ? item.node.publicURL : null
        imgUrl.fluid = item.node.childImageSharp ? item.node.childImageSharp.fluid : null
        imgUrl.gatsbyImageData = item.node.childImageSharp ? item.node.childImageSharp.gatsbyImageData : null

        // imgUrl = item.node.publicURL
      }
      return ("")

    })
    return (imgUrl)
  }



  const ratingChanged = (newRating) => {
    if (newRating <= 3) {
      window.location.replace('/contact-us');
    } else {
      window.location.replace('https://www.google.com/search?q=tower+heating&oq=tower+heating&aqs=chrome..69i57j69i60l2j69i61j0l2.2737j0j7&sourceid=chrome&ie=UTF-8#lrd=0x487b3b19ea7c49f1:0xb595cfd32bf6ce6b,1,,');
    }
  };


  const [modalIsOpen, setIsOpen] = useState(false);
  const modalContainer = useRef();

  useEffect(() => {
    const closePopup = e => {

      if (modalContainer.current !== null && modalContainer.current !== undefined && !modalContainer.current.contains(e.target)) {

        setIsOpen(false)

      }

    };

    document.addEventListener('mousedown', closePopup);

    return () => {
      document.removeEventListener('mousedown', closePopup);
    };


  });



  return (
    <Layout SEOTitle={data.strapiReviewPage.Seo.metaTitle} SEODescription={data.strapiReviewPage.Seo.metaDescription} footerDisclaimer={data.strapiReviewPage.FooterDisclaimer}>

      {data.strapiReviewPage?.PageComponents?.map((item, index) => {

        switch (item.strapi_component) {
          
          case "page-components.header":
            return (
              <>
                {/* <RichSnippet type={"Organization"} data={richSnippetData} /> */}
                <Header key={index}
                  Title={item.Title}
                  Subtitle={item.Subtitle}
                  FirstCTALabel={item.FirstCTALabel}
                  FirstCTAURL={item.FirstCTAURL}
                  Background={item.Background !== null ? getImgUrl(item.Background.localFile___NODE) : ""}
                  Image={item.Image !== null ? getImgUrl(item.Image.localFile___NODE) : null}
                  BackgroundColor={item.BackgroundColorHeader}
                >
                </Header>
              </>

            )

          default:
            return ("nu merge")
        }
      })}


      <div className="card-page">

        <div className="btn-container">
          <button className="review-btn" onClick={() => setIsOpen(true)}>Leave a review</button>
          {
            modalIsOpen ?
              (
                <div id="myModal" className="modal">
                  <div className="modal-content" ref={modalContainer}>
                    <button className="close" onClick={() => setIsOpen(false)}>&#10006;</button>
                    <h2 className="center-text">We'd Love To Hear From You!</h2>
                    <p className="rating-text">Click on a star below...</p>
                    <div className="rating-stars">
                      <ReactStars
                        count={5}
                        onChange={ratingChanged}
                        size={50}
                        activeColor="#ffd700"
                      />
                    </div>


                  </div>
                </div>

              )
              :
              (
                <div> </div>
              )
          }
        </div>

        <div className="uk-child-width-1-3@m uk-child-width-1-2@s  uk-grid-match uk-grid uk-text-center" uk-grid="true" id="margin-cardpage">
          {
            data.allStrapiReviews.nodes.map((item, index) => {
              return (
                <ReviewCard
                  key={index}
                  Title={item.Title}
                  Author={item.Author}
                  Description={item.Description}
                  Image={item.Image}
                  Alt={item.Alt}

                />
              )

            })
          }
        </div>


      </div>

    </Layout >
  )


}

export default Reviews